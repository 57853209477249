
import React from 'react';
import PiePagina from './footer'
import Nav from './nav'
import TablaHome from './tablaHome';
import icon_welcome from './img/icon-welcome.svg';
import header_home from './img/header-home.jpg';
import icon_stats from './img/icon-stats.svg';
import icon_stats2 from './img/icon-stats-2.svg';
import icon_star from './img/icon-star.svg';
import icon_note from './img/icon-notes.svg';
import icon_stats3 from './img/icon-stats-2.svg';
import icon_graph_example_2 from './img/graph-example-2.svg';
import icon_graph_example_3 from './img/graph-example-3.svg';
import icon_money from './img/icon-money.svg';

class Documentacion extends React.PureComponent {

    render() {
        return (
            <React.Fragment>
                <body className="page page-home">
                    <Nav></Nav>
                    <main>
                        <div className="page-header-img">
                            <img src={header_home} alt=""/>
                        </div>
                        <section className="page__intro">
                            <div className="container">
                                <header>
                                    <img src={icon_welcome} alt="" />
                                    <h4>Documentacion</h4>
                                </header>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                    quis nostrud exercitation ullamco laboris nisi ut aliquip.</p>
                            </div>
                        </section>
                        <section className="number-blocks">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-3">
                                        <article className="number-block block border-radius--big bg--green"> <a href="">+</a>
                                            <header>
                                                <img src={icon_stats} alt=""/>
                                                <h4>Título 01</h4>

                                            </header>
                                            <div className="number-block__content"> <span className="number">12.638</span>
                                                <a href="#">Lorem ipsum dolor »</a>

                                            </div>
                                        </article>
                                    </div>
                                    <div className="col-md-3">
                                        <article className="number-block block border-radius--big bg--light-green"> <a href="#">+</a>

                                            <header>
                                                <img alt="" src={icon_stats2} />
                                                <h4>Título 01</h4>

                                            </header>
                                            <div className="number-block__content"> <span className="number">12.638</span>
                                                <a href="#">Lorem ipsum dolor »</a>

                                            </div>
                                        </article>
                                    </div>
                                    <div className="col-md-3">
                                        <article className="number-block block border-radius--big bg--orange"> <a href="#">+</a>

                                            <header>
                                                <img alt="" src={icon_star} />
                                                <h4>Título 01</h4>

                                            </header>
                                            <div className="number-block__content"> <span className="number">12.638</span>
                                                <a href="#">Lorem ipsum dolor »</a>

                                            </div>
                                        </article>
                                    </div>
                                    <div className="col-md-3">
                                        <article className="number-block block border-radius--big bg--blue"> <a href="#">+</a>

                                            <header>
                                                <img alt="" src={icon_note} />
                                                <h4>Título 01</h4>

                                            </header>
                                            <div className="number-block__content"> <span className="number">12.638</span>
                                                <a href="#">Lorem ipsum dolor »</a>

                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="mt-md-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-3">
                                        <article className="vertical-block block bg--blue border-radius--normal">
                                        <header>
                                     <h5 className="text-color--white">Título 01</h5>
                                </header>
                                <div className="items-slider">
                                    <ul className="list-unstyled">
                                        <li className="text-color--white">
                                            <img src={icon_money} className="mr-2"/>Lorem ipsum</li>
                                        <li className="text-color--white">
                                            <img src={icon_money} className="mr-2"/>Lorem ipsum</li>
                                        <li className="text-color--white">
                                            <img src={icon_money} className="mr-2"/>Lorem ipsum</li>
                                        <li className="text-color--white">
                                          <img src={icon_money} className="mr-2"/>Lorem ipsum</li>
                                        <li className="text-color--white">
                                            <img src={icon_money} className="mr-2"/>Lorem ipsum</li>
                                    </ul>
                                    <ul className="list-unstyled">
                                        <li className="text-color--white">
                                            <img src={icon_money} className="mr-2"/>Lorem ipsum</li>
                                        <li className="text-color--white">
                                            <img src={icon_money} className="mr-2"/>Lorem ipsum</li>
                                        <li className="text-color--white">
                                            <img src={icon_money} className="mr-2"/>Lorem ipsum</li>
                                        <li className="text-color--white">
                                            <img src={icon_money} className="mr-2"/>Lorem ipsum</li>
                                        <li className="text-color--white">
                                            <img src={icon_money} className="mr-2"/>Lorem ipsum</li>
                                    </ul>
                                    <ul className="list-unstyled">
                                        <li className="text-color--white">
                                            <img src={icon_money} className="mr-2"/>Lorem ipsum</li>
                                        <li className="text-color--white">
                                            <img src={icon_money} className="mr-2"/>Lorem ipsum</li>
                                        <li className="text-color--white">
                                            <img src={icon_money} className="mr-2"/>Lorem ipsum</li>
                                        <li className="text-color--white">
                                            <img src={icon_money} className="mr-2"/>Lorem ipsum</li>
                                        <li className="text-color--white">
                                            <img src={icon_money} className="mr-2"/>Lorem ipsum</li>
                                    </ul>
                                </div>
                                        </article>
                                    </div>
                                    <div className="col-md-3">
                                        <article className="vertical-block block border-radius--normal bg--gray">
                                            <header>
                                                <h5>Título 02</h5>
                                            </header>
                                            <img src={icon_graph_example_2} className="mt-3" alt=""/>
                                        </article>
                                    </div>
                                    <div className="col-md-6">
                                        <article className="vertical-block block border-radius--normal bg--green text-color--white">
                                            <header>
                                                <img src={icon_stats3} alt=""/>
                                                <h5 className="text-color--white">Título 03</h5>

                                            </header>
                                            <img src={icon_graph_example_3} alt="" className="mt-4" />
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div className="container">
                            <section className="bg--orange message border-radius--big mt-5">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h5 className="text-color--white">Lorem ipsum dolor sit amet</h5>

                                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem.</p>
                                    </div>
                                </div>
                                <div className="row justify-content-center justify-content-md-end mt-3 mt-md-0">
                                    <div className="col-md-6 text-center text-md-right"> <a href="" className="btn bg--white">Cancelar</a>
                                        <a href="" className="btn bg--white">Aceptar</a>

                                    </div>
                                </div>
                            </section>
                        </div>
                    </main>
                 <TablaHome></TablaHome>
                </body>
                <PiePagina></PiePagina>              
            </React.Fragment >
        )
    }
}

export default Documentacion;