
import React from 'react';
import PiePagina from './footer'
import Nav from './nav'
import icon_welcome from './img/icon-welcome.svg';
import header_home from './img/header-home.jpg';
import icon_pdf from './img/icon-pdf.png';
import {Form } from 'react-bootstrap';
import axios from 'axios';
import Moment from 'moment';
import MaterialTable from "material-table";
import loading from './img/loading.gif';

class Proceso extends React.PureComponent {

    constructor(props) {
        super(props);
        // No llames this.setState() aquí!
        
        this.state = {
            campos: [],
            tablaproceso: [],
            idtbl_campo: 0,
            kilos: 0,
            recepciones:0,
            primerdia_mes: '',
            ultimodia_mes: '',
            isLoading : false,

        };
        this.validaLogin = this.validaLogin.bind(this);
        this.obtenerSelector = this.obtenerSelector.bind(this);
        this.obtenerTabla = this.obtenerTabla.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.validaLogin()
    }

  
    validaLogin() {
        if (localStorage.getItem('idtbl_usuario') === undefined) {
            alert("Sesion no ha sido iniciada");
            window.location.href = "/"
        } else {
            this.obtenerSelector();
        }
    }
 
    handleChange(event) {
        event.preventDefault();
        this.obtenerTabla(event.target.value);
    }
    async obtenerSelector() {
        const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario') };
        try {
            const res = await axios.post('https://productor.appsjf.cl/sanJoseFarmsPHP/selectorhome.php', obj);
            this.setState({
                campos: res.data,
            });
            this.setState({ idtbl_campo: this.state.campos[0].idtbl_campo });
            
            var date = new Date();
            var primerDia = new Date(date.getFullYear(), date.getMonth(), 1);
            var ultimoDia = new Date();
            this.setState({ primerdia_mes: Moment(primerDia).format('YYYY-MM-DD')  })
            this.setState({ ultimodia_mes: Moment(ultimoDia).format('YYYY-MM-DD')  })
            
            if (this.state.campos === undefined) {
                alert('Usuario no posee campos asignados');
                this.setState({ campos: [] })
            }
        } catch (error) {
            console.error(error);
        }
        this.obtenerTabla(this.state.campos[0].idtbl_campo);
    }

   async  obtenerTabla(value) {
        const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario'), idtbl_campo: value };
        try {
          const res = await axios.post('https://productor.appsjf.cl/sanJoseFarmsPHP/tablaproceso.php');
          console.log(res);
            this.setState({
                tablaproceso: res.data
            });
        } catch (error) {
            console.error(error);
        }
    }
    
    render() {
        return (
            <React.Fragment>
                <body class="page page-home">
                    <Nav></Nav>
                    <main>
                        <div class="page-header-img">
                            <img src={header_home} alt="" />
                        </div>
                        <section class="page__intro">
                            <div class="container">
                                <header>
                                    <img src={icon_welcome} alt="" />
                                    <h4>Proceso</h4>
                                </header>
                                <p>Estimado productor en este sitio encontrará información relevante del proceso de sus productos.</p>
                            </div>
                        </section>

                        <section className="table table-responsive mt-md-5">
                        <div className="container table">
                            <div className="table__header table__row border-radius--normal bg--white">
                                <div className="row">
                                    <div className="col-4 col-md">
                                        <label className="form-label"><h2>Campos</h2></label>
                                        {
                                            <Form.Control as="select" defaultValue={this.state.campos[0]} id={"1"} onChange={this.handleChange}>
                                                {this.state.campos.map((r) => { return (< option key={r.idtbl_campo} value={r.idtbl_campo}> {r.nombre_campo} </option>) })}
                                            </Form.Control>
                                         }   
                                    </div>
                                    <div className="col-4 col-md">
                                        <label className="form-label"><h2>Desde</h2></label>
                                        {
                                            <Form.Control type="date"  id={"1"} defaultValue={this.state.primerdia_mes} >
                                            </Form.Control>
                                        }
                                    </div>  
                                  <div className="col-4 col-md">
                                  <label className="form-label"><h2>Hasta</h2></label>
                                        {
                                            <Form.Control type="date" id={"2"} defaultValue={this.state.ultimodia_mes} >
                                            </Form.Control>
                                        }
                                      
                                      </div>                                   
                                </div> 
                            </div> 
                        </div>
                    </section>
                    
                    { this.state.isLoading === true ? 
                    
                    <section className="table table-responsive mt-md-5">
                         <div className="container table">
                           <div align="center"><img src={loading} width="15%" alt="loading..." /></div>
                           </div>
                     </section>
                         : 

                     <section className="table table-responsive mt-md-5">
                         <div className="container table">
                             <MaterialTable columns={[
                                 { title: "Lote",  field: "zlote_proceso", align:"center" ,  filtering: false},
                                 { title: "Fecha", field: "zfecha_proceso",     filtering: false },
                                 { title: "Total Ingreso",  field: "ztotal_ingreso",  filtering: true, searchable: true },
                                 { title: "Total Salida", field: "ztotal_salida", align:"center" , filtering: false },
                                 { title: "Exportación",  field: "zexportacion", align:"left" ,  filtering: false },
                                 { title: "Estado",     field: "zEstado_proceso",   align:"left",  filtering: false },
                                 { title: "Descarga",        field: "zUrl", align:"right" , filtering: false ,  render: rowData => <a target={"_blank"} href={rowData.zUrl}> <img src={icon_pdf} height={45}  /> </a> }]}
                                 data= {this.state.tablaproceso }
                                 options={{ filtering: false, search: false, showTitle: false,  exportAllData: true, exportButton: true, exportFileName: 'Reporte Recepción San Jose Farms' }}
                             />
                         </div>
                     </section>
                     }

                    </main>
                </body>
                <PiePagina></PiePagina>
            </React.Fragment >
        )
    }
}


export default Proceso;