import React from 'react';
import icon_exit from './img/icon-exit.svg';
import svgiconproduct from './img/icon-productor-gray.svg';
import icon_envelope from './img/icon-envelope.svg';
import icon_bell from './img/icon-bell.svg';
import icon_home from './img/icon-home.svg';
import logo from './img/logo.jpg';
import icon_user from './img/icon-user.svg';
import icon_search from './img/icon-search.svg';
import Cabecera from './header';


class Nav extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    render() {

        return (
            <React.Fragment>
            <Cabecera/>
            
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container"> <a className="navbar-brand" href="#"><img src={logo} alt="" width="30%" /></a>
                            <button className="navbar-toggler"
                                type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                                aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"></span>
                            </button>
                            <div>
                                <div className="navbar-top">
                                    <div className="portal-type">
                                        <img src={svgiconproduct} />
                                        <h4>Portal Productor</h4>
                                    </div>
                                    <form action="" className="form-search">
                                        <div>
                                            <input type="search" placeholder="Buscar..." className="border-radius--big" />
                                            <button type="submit">
                                                <img src={icon_search} />
                                            </button>
                                        </div>
                                    </form>
                                    <div className="navbar-actions">
                                        <li>
                                            <a href="#"><span className="bg--light-green">5</span><img src={icon_envelope} /> </a>
                                        </li>
                                        <li>
                                            <a href="#"><span className="bg--red">2</span><img src={icon_bell} /> </a>
                                        </li>
                                        <li><a href="#"><img src={icon_user} /></a>
                                        </li>
                                        <li><a href="#"><img src={icon_exit} /></a>
                                        </li>
                                    </div>
                                </div>
                                <div className="navbar-bottom">
                                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                                        <ul className="navbar-nav">
                                            <li className="nav-item"> <a href="home" className="nav-link">
                                                <img src={icon_home} alt="" />
                                            </a>
                                            </li>
                                            <li className="nav-item active"> <a className="nav-link" href="home">Recepción</a>

                                            </li>
                                            <li className="nav-item"> <a className="nav-link" href="calidad">Calidad</a>

                                            </li>
                                            <li className="nav-item"> <a className="nav-link" href="proceso">Procesos</a></li>
                                            <li className="nav-item"> Certificado</li>
                                            <li className="nav-item"> Cuenta corriente</li>
                                            <li className="nav-item"> Documentación</li>
                                            <li className="nav-item"> <a className="nav-link" href="agronomo"> Agrónomo </a></li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
             </React.Fragment>
      )
    }
    
}
    export default Nav;