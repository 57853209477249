import React from 'react';
import PiePagina from './footer'
import Nav from './nav'
import icon_welcome from './img/icon-welcome.svg';
import header_home from './img/header-home.jpg';
import icon_stats from './img/icon-stats.svg';
import icon_stats2 from './img/icon-stats-2.svg';
import { Table, Form } from 'react-bootstrap';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import MaterialTable from "material-table";
import loading from './img/loading.gif';

class Home extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            campos: [],
            lista_especies:[],
            tablaHome: [],
            idtbl_campo: 0,
            kilos: 0,
            kilos7dias: 0,
            recepciones: 0,
            filtro_material: [],
            datostabla: [],
            isLoading : false,
            cantidadEspecies: 1,
            esfiltrado : false,
            filtro : '',
            tablaSinFiltrar: []
        };
        this.validaLogin = this.validaLogin.bind(this);
        this.obtenerSelector = this.obtenerSelector.bind(this);
        this.obtenerTabla = this.obtenerTabla.bind(this);
        this.obtenerKilosRecepcionados = this.obtenerKilosRecepcionados.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.obtenerNumerosRecepcionados = this.obtenerNumerosRecepcionados.bind(this);
        this.obtenerKilosRecepcionados7dias = this.obtenerKilosRecepcionados7dias.bind(this);
        this.obtenerEspecies = this.obtenerEspecies.bind(this);
        this.handleChangeEspecies = this.handleChangeEspecies.bind(this);
    }

    componentDidMount() {
        this.validaLogin()
    }

    validaLogin() {
        if (localStorage.getItem('idtbl_usuario') === undefined) {
            alert("Sesion no ha sido iniciada");
            window.location.href = "/"
        } else {
            this.obtenerSelector();
        }
    }

    handleChange(event) {
        event.preventDefault();
        this.obtenerTabla(event.target.value);
        this.obtenerKilosRecepcionados(event.target.value);
        this.obtenerNumerosRecepcionados(event.target.value);
        this.obtenerKilosRecepcionados7dias(event.target.value);
        this.obtenerEspecies(event.target.value);
    }

    handleChangeEspecies(event){
        event.preventDefault();
        var valor = event.target.value;
        let tabla = this.state.tablaSinFiltrar;
        if(valor !== '' ){
            let array = tabla.filter( function (especie) {  { return especie.material === valor}});
            this.setState({tablaHome: array})
            
        }else{
            this.setState({tablaHome: tabla})
        }
        
    }

    async obtenerSelector() {
        const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario') };
        try {
            await axios.post('https://productor.appsjf.cl/sanJoseFarmsPHP/selectorhome.php', obj).then( response=> {this.setState({campos: response.data}) } );
            this.setState({ idtbl_campo: this.state.campos[0].idtbl_campo });
            if (this.state.campos === undefined) {
                alert('Usuario no posee campos asignados');
                this.setState({ campos: [] })
            } else {
                this.obtenerTabla(this.state.campos[0].idtbl_campo);             
                this.obtenerKilosRecepcionados(this.state.campos[0].idtbl_campo);
                this.obtenerNumerosRecepcionados(this.state.campos[0].idtbl_campo);
                this.obtenerKilosRecepcionados7dias(this.state.campos[0].idtbl_campo )
                this.obtenerEspecies(this.state.campos[0].idtbl_campo)
            }
        } catch (error) {
            console.error(error);
        }
    }

  obtenerTabla(value) {
        const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario'), idtbl_campo: value };
        let data = []
        try { 
            this.setState({isLoading: true})
            axios.post('https://productor.appsjf.cl/sanJoseFarmsPHP/tablahome.php', obj).then(response => 
            {
                const datos = response.data  
                if (datos  !== undefined){
                    this.setState({tablaSinFiltrar: response.data})
                    this.setState({tablaHome: response.data})
                    this.setState({isLoading: false})
                }else{
                    
                    console.log("data vacia");
                }
            })                
         }catch(error) {
            console.error(error);
         }
    }

    async obtenerKilosRecepcionados(value) {
        const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario'), idtbl_campo: value };
        try {
            const res = await axios.post('https://productor.appsjf.cl/sanJoseFarmsPHP/kilosrecepcionados.php', obj);
            this.setState({
                kilos7dias: res.data[0]
            });
        } catch (error) {
            console.error(error);
        }
    }
   
    async obtenerEspecies(value) {
        const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario'), idtbl_campo: value };
        try {
            const res = await axios.post('https://productor.appsjf.cl/sanJoseFarmsPHP/numeroespecies.php', obj);
            this.setState({ lista_especies: res.data });
            if (res.data !== undefined){
                var cantidad =  res.data.length;
                this.setState({ cantidadEspecies : cantidad})    
            }
        }catch (error){
            console.error(error);
        }
    }

    async obtenerKilosRecepcionados7dias(value) {
        const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario'), idtbl_campo: value };
        try {
            const res = await axios.post('https://productor.appsjf.cl/sanJoseFarmsPHP/kilosrecepcionados7dias.php', obj);
            this.setState({
                kilos: res.data[0]
            });
        } catch (error) {
            console.error(error);
        }
    }

    async obtenerNumerosRecepcionados(value) {
        const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario'), idtbl_campo: value };
        try {
            const res = await axios.post('https://productor.appsjf.cl/sanJoseFarmsPHP/numerorecepciones.php', obj);
            this.setState({
                recepciones: res.data[0]
            });
        } catch (error) {
            console.error(error);
        }
    }

    render() {
        return (
            <React.Fragment>
                <body className="page page-home">
                    <Nav></Nav>
                    <main>
                        <div className="page-header-img">
                            <img src={header_home} alt="" />
                        </div>
                        <section className="page__intro">
                            <div className="container">
                                <header>
                                    <img src={icon_welcome} alt="" />
                                    <h4>Hola, {localStorage.getItem('nombre_usuario')}  {localStorage.getItem('apellido_usuario')}</h4>
                                </header>
                                <p>Estimado productor en este sitio encontrará información relevante de su recepciones.</p>
                            </div>
                        </section>
                      <section className="table table-responsive mt-md-5">
                        <div className="container table">
                            <div className="table__header table__row border-radius--normal bg--white">
                                <div className="row">
                                    <div className="col-4 col-md">
                                        <label className="form-label"><h2>Campos</h2></label>
                                        {
                                            <Form.Control as="select" defaultValue={this.state.campos[0]} id={"1"} onChange={this.handleChange}>
                                                {this.state.campos.map((r) => { return (< option key={r.idtbl_campo} value={r.idtbl_campo}> {r.nombre_campo} </option>) })}
                                            </Form.Control>
                                         }   
                                    </div>
                                    <div className="col-4 col-md">
                                        <label className="form-label"><h2>Especies</h2></label>
                                        {
                                            <Form.Control as="select" defaultValue={''} id={"1"} onChange={this.handleChangeEspecies}>
                                                <option value={''} > Todos las especies</option>
                                                    {this.state.lista_especies.map((r) => { 
                                                        return (< option key={r.especie} value={r.especie}> {r.especie} </option>) 
                                                    }  )}
                                            </Form.Control>
                                        }
                                    </div>  
                                  <div className="col-4 col-md"></div>                                   
                                </div> 
                            </div> 
                        </div>
                    </section>
                    
                        <section className="number-blocks">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-3">
                                        <article className="number-block block border-radius--big bg--green">   
                                            <header>
                                                <img src={icon_stats} alt="" />
                                                <h2>Kilos recepcionados</h2>
                                            </header>
                                            <div className="number-block__content"> <span className="number"> {<NumberFormat value={this.state.kilos.cantidad} displayType={'text'} thousandSeparator={true} />}</span>
                                                <a></a>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div className="col-md-3">
                                        <article className="number-block block border-radius--big bg--light-green">
                                            <header>
                                                <img alt="" src={icon_stats2} />
                                                <h2>Kilos últimos 7 días</h2>
                                            </header>
                                            <div className="number-block__content"> <span className="number">{<NumberFormat value={this.state.kilos7dias.cantidad} displayType={'text'} thousandSeparator={true} />}</span>
                                                <a></a>
                                            </div>
                                        </article>
                                    </div>
                                    <div className="col-md-3">
                                        <article className="number-block block border-radius--big bg--orange"> 
                                            <header>
                                                <img alt="" src={icon_stats2} />
                                                <h2>Número de recepciones</h2>
                                            </header>
                                            <div className="number-block__content"> <span className="number">{<NumberFormat value={this.state.recepciones.numero} displayType={'text'} thousandSeparator={true} />}</span>
                                                <a></a>
                                            </div>
                                        </article>
                                    </div>
                                    <div className="col-md-3">
                                        <article className="number-block block border-radius--big bg--light-blue">
                                            <header>
                                                <img alt="" src={icon_stats2} />
                                                <h2>Número de especies</h2>
                                            </header>
                                            <div className="number-block__content"> <span className="number">{<NumberFormat value={this.state.cantidadEspecies} displayType={'text'} thousandSeparator={true} />}</span>
                                                <a></a>
                                            </div>
                                        </article>
                                    </div>
                                    
                                </div>
                            </div>
                        </section>
                    </main>
                    { this.state.isLoading === true ? 
                    
                    <section className="table table-responsive mt-md-5">
                        <div className="container table">
                          <div align="center"><img src={loading} width="15%" alt="loading..." /></div>
                          </div>
                    </section>
                        : 
                    <section className="table table-responsive mt-md-5">
                        <div className="container table">
                            <MaterialTable columns={[
                                { title: "ID",        field: "idCampo", align:"center" ,  filtering: false},
                                { title: "PRODUCTOR", field: "campo",     filtering: false },
                                { title: "MATERIAL",  field: "material",  filtering: true, searchable: true },
                                { title: "GUIA",      field: "guia",  align:"center" ,    type: "numeric", filtering: false },
                                { title: "CATEGORIA", field: "categoria", align:"center" , filtering: false },
                                { title: "VARIEDAD",  field: "nombre_variedad", align:"left" ,  filtering: false },
                                { title: "FECHA",     field: "fecha",   align:"left",  filtering: false },
                                { title: "KG",        field: "cantidad", align:"right" ,  type: "numeric", filtering: false }]}
                                data= {this.state.tablaHome }
                                options={{ filtering: false, search: false, showTitle: false,  exportAllData: true, exportButton: true, exportFileName: 'Reporte Recepción San Jose Farms' }}
                            />
                        </div>
                    </section>
                    }
                </body>
                <PiePagina></PiePagina>
            </React.Fragment >
        )
    }
}

export default Home;