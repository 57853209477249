import React from 'react';
import axios from 'axios';
import servicios from './servicios';
class Registro extends React.PureComponent {
    
    constructor(){
        super();
        this.state = {
            rut: "",
            apellido : "",
            correo : "",
            pass : "",
            nombre : "",
            segundaPass : "",
            dv : "",

        };
        this.handleChangeRut = this.handleChangeRut.bind(this);
        this.registrarUsuario = this.registrarUsuario.bind(this);
        this.handleChangeNombre = this.handleChangeNombre.bind(this);
        this.handleChangeApellido = this.handleChangeApellido.bind(this);
        this.handleChangeCorreo = this.handleChangeCorreo.bind(this);
        this.handleChangePass = this.handleChangePass.bind(this);
        this.handleChangeSegundaPass = this.handleChangeSegundaPass.bind(this);
    }

    handleChangeNombre(event) {
        event.preventDefault();
        this.setState({nombre: event.target.value});
    }
    handleChangeRut(event) {
        event.preventDefault();
        this.setState({rut: event.target.value});
    }
    handleChangeApellido(event) {
        event.preventDefault();
        this.setState({apellido: event.target.value});
    }
    handleChangeCorreo(event) {
        event.preventDefault();
        this.setState({correo: event.target.value});
    }
    handleChangePass(event) {
        event.preventDefault();
        this.setState({pass: event.target.value});
    }
    handleChangeSegundaPass(event) {
        event.preventDefault();
        this.setState({segundaPass: event.target.value});
    }

    formateaRut(rut) {
        if (rut !== undefined){
        var actual = rut.replace(/^0+/, "");
        if (actual !== '' && actual.length > 1) {
            var sinPuntos = actual.replace(/\./g, "");
            var actualLimpio = sinPuntos.replace(/-/g, "");
            var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
            var rutPuntos = "";
            var i = 0;
            var j = 1;
            for (i = inicio.length - 1; i >= 0; i--) {
                var letra = inicio.charAt(i);
                rutPuntos = letra + rutPuntos;
                if (j % 3 === 0 && j <= inicio.length - 1) {
                    rutPuntos = "." + rutPuntos;
                }
                j++;
                 }
                var dv = actualLimpio.substring(actualLimpio.length - 1);
                rutPuntos = rutPuntos + "-" + dv;
            }
            this.setState({rut: actualLimpio});
            this.setState({dv: dv})
         }
    }

     registrarUsuario(event) {
        event.preventDefault();
        const obj = {correo:this.state.correo, pass : this.state.pass, apellido : this.state.apellido, dv:this.state.dv,  nombre: this.state.nombre, rut: this.state.rut};
        try {
            if(this.state.segundaPass === this.state.pass){
         
            // este
               const res = axios.post('https://productor.appsjf.cl/sanJoseFarmsPHP/registrarUsuario.php',obj);  
            
                alert("Usuario Creado");
            }else{
                 alert("Las contraseñas deben coincidir");
            }         //window.location.href = "/home"
      
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        return (

            <React.Fragment>
            <form  onSubmit={this.registrarUsuario} className="login__form active"  id="register__form">
                    <div className="row">
                        <div className="col-md-6">
                           <div>
                                <input type="text" placeholder="Nombre" value={this.state.nombre} onChange={this.handleChangeNombre} maxLength={45} required />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div>
                               <input type="text" placeholder="Apellido" maxLength={45}  value={this.state.apPat}  onChange={this.handleChangeApellido}  required/>
                           </div>
                       </div>
                        <div className="col-md-6">
                           <div>
                           <input type="text" id="rut" name="rut" required  value={this.state.rut} onChange={this.handleChangeRut} maxLength={12} oninput={this.formateaRut(this.state.rut)} placeholder="Ingrese RUT"/>
                            </div>
                        </div>
                        <div className="col-md-6">
                           <div>
                                <input type="email" placeholder="Correo" value={this.state.correo} onChange={this.handleChangeCorreo} maxLength={45} required/>
                            </div>
                        </div>

                        <div className="col-md-6">
                           <div>
                                <input type="password" placeholder="Contraseña" value={this.state.pass}   onChange={this.handleChangePass} maxLength={45} required />
                            </div>
                        </div>
                        
                        <div className="col-md-6">
                           <div>
                                <input type="password" placeholder="Validación Contraseña" value={this.state.segundaPass}  onChange={this.handleChangeSegundaPass} maxLength={45} required/>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div>
                                <input type="submit" value="Solicitar Registro" />
                            </div>
                        </div>
                    </div>
            </form>
            </React.Fragment>
                )
            }
   }   

export default Registro;