
import React from 'react';
import estilo from  './css/style.css';

class Header extends React.PureComponent {

    render() {
      return (   
         <head> 
             <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"/>
             <script async src="http://www.googletagmanager.com/gtag/js?id=UA-163313761-1"></script>
             <meta charSet="UTF-8" />
             <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
             <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
             <title>Portales SJP</title>
             <meta name="robots" content="noindex, nofollow"/>
             <meta httpEquiv="Content-Type" content="text/html; charset=utf-8"/>
             <meta name="language" content="Spanish"/>
             <meta name="author" content="Matías Valle"/>
             <link rel="stylesheet" href={estilo} />
             <link rel="manifest" href="img/site.webmanifest"/>
             <link rel="mask-icon" href="img/safari-pinned-tab.svg" color="#333333"/>
             <meta name="msapplication-TileColor" content="#da532c"/>
             <meta name="theme-color" content="#333333"/>
             <link href="http://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap" rel="stylesheet"/>
       </head>
       )
    }
}
export default Header;


