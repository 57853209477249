import React from 'react';
import './App.css';
import Index from './components/index'
import Home from './components/home'
import Calidad from './components/calidad' 
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Recepcion from './components/recepcion';
import Proceso from './components/proceso';
import CuentaCorriente from './components/cuentacorriente';
import Certificado from './components/certificado';
import Documentacion from './components/documentacion';
import Agronomo from './components/agronomo';


export default () => (

        <BrowserRouter>
             <Switch>            
                <Route exact path='/' component={Index} />  
                <Route exact path='/home' component={Home} />    
                <Route exact path='/calidad' component={Calidad} />    
                <Route exact path='/recepcion' component={Recepcion} />  
                <Route exact path='/proceso' component={Proceso} />  
                <Route exact path='/cuentacorriente' component={CuentaCorriente} />                  
                <Route exact path='/certificado' component={Certificado} />  
                <Route exact path='/documentacion' component={Documentacion} />  
                <Route exact path='/agronomo' component={Agronomo} />  
                
            </Switch>
        </BrowserRouter>

);