
import React from 'react';

class Footer extends React.PureComponent {

    render() {
      return (   
                <footer className="primary-footer"> 
                    <div className="container text-center">
                        <h6>Copyright © 2020 San José Farms. All rights reserved.</h6>
                    </div>
                </footer>
       )
    }
}
export default Footer;






