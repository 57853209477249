
import React from 'react';
import icon_search from './img/icon-search.svg';

class TablaHome extends React.PureComponent {

    componentDidMount(){
        
    }


    render() {
        return (
            <React.Fragment>
                <section className="table table-responsive mt-md-5">
                    <div className="container table">
                        
                        <div className="table__header table__row border-radius--normal bg--light-green">
                            <div className="row">
                                <div className="col-1 offset-md-1">
                                    <h5 className="text-color--white">ID</h5>

                                </div>
                                <div className="col-4 col-md">
                                    <h5 className="text-color--white">Lorem Ipsum</h5>

                           
                                </div>
                                <div className="col-3 col-md">
                                    <h5 className="text-color--white">Lorem Ipsum</h5>

                                </div>
                                <div className="col-3 col-md">
                                    <h5 className="text-color--white">Lorem Ipsum</h5>

                                
                                </div>
                            </div>
                        </div>
                        <div className="table__row border-radius--normal bg--gray">
                            <div className="row">
                                <div className="col-1 col-md-1 offset-md-1"> <span>1</span>

                                </div>
                                <div className="col-4 col-md"> <span>xxxxxx</span>

                                </div>
                                <div className="col-3 col-md"> <span>xxxxxx</span>

                                </div>
                                <div className="col-3 col-md"> <span>xxxxxx</span>

                                </div>
                            </div>
                        </div>
                        <div className="table__row border-radius--normal bg--gray">
                            <div className="row">
                                <div className="col-1 col-md-1 offset-md-1"> <span>2</span>

                                </div>
                                <div className="col-4 col-md"> <span>xxxxxx</span>

                                </div>
                                <div className="col-3 col-md"> <span>xxxxxx</span>

                                </div>
                                <div className="col-3 col-md"> <span>xxxxxx</span>

                                </div>
                            </div>
                        </div>
                        <div className="table__row border-radius--normal bg--gray">
                            <div className="row">
                                <div className="col-1 col-md-1 offset-md-1"> <span>3</span>

                                </div>
                                <div className="col-4 col-md"> <span>xxxxxx</span>

                                </div>
                                <div className="col-3 col-md"> <span>xxxxxx</span>

                                </div>
                                <div className="col-3 col-md"> <span>xxxxxx</span>

                                </div>
                            </div>
                        </div>
                        <div className="table__row border-radius--normal bg--gray">
                            <div className="row">
                                <div className="col-1 col-md-1 offset-md-1"> <span>4</span>
                                </div>
                                <div className="col-4 col-md"> <span>xxxxxx</span>
                                </div>
                                <div className="col-3 col-md"> <span>xxxxxx</span>
                                </div>
                                <div className="col-3 col-md"> <span>xxxxxx</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}
export default TablaHome;