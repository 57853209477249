import React from 'react';
import imgLoginbg from './img/home.jpg';
import svgiconproduct from './img/icon-productor.svg';
import iconlogin_user from './img/icon-login-user.svg';
import iconlogin_pass from './img/icon-login-password.svg';
import logo from './img/logo.jpg';
import HeaderComponent from './header'
import PiePagina from './footer'
import axios from 'axios';
import Registro from './registro'

class Index extends React.PureComponent {

    constructor(props) {
        super(props);
    
        this.state = {
            mail: '',
            password:'',
            showButton: false,
            usuario: [],
            BtnLoginColorVerde : "active",
            BtnRegistrarColorVerde: ""
        };
        this.handleChangeMail = this.handleChangeMail.bind(this);
        this.handleChangePass = this.handleChangePass.bind(this);
        this.obtenerUsuario = this.obtenerUsuario.bind(this);
    }

    handleChangeMail(event) {        
        event.preventDefault();
        this.setState({mail: event.target.value});
      }

      handleChangePass(event) {
        event.preventDefault();
          this.setState({password: event.target.value});
      }


    mostrarRegistro = (opcion) => {
        if (opcion === true) { 
            this.setState({showButton:false, BtnLoginColorVerde:"active", BtnRegistrarColorVerde: "" })            
        }
        else 
        {
            this.setState({showButton:true, BtnLoginColorVerde:"", BtnRegistrarColorVerde: "active" })                
        }        
    }

    async obtenerUsuario(event) {
        event.preventDefault();
        const obj = { mail: this.state.mail, password: this.state.password}; 
              try {
                const res = await axios.post('https://productor.appsjf.cl/sanJoseFarmsPHP/obtenerUsuario.php',obj);
                this.setState({
                    usuario: res.data[0]
                });    
                if(this.state.usuario !== undefined){
                    localStorage.setItem('idtbl_usuario',  this.state.usuario.idtbl_usuario)  
                    localStorage.setItem('nombre_usuario', this.state.usuario.nombre_usuario)
                    localStorage.setItem('apellido_usuario', this.state.usuario.apellido_usuario)
                    localStorage.setItem('rut_usuario', this.state.usuario.rut_usuario)
                    localStorage.setItem('dv_usuario', this.state.usuario.dv_usuario)
                    localStorage.setItem('mail_usuario', this.state.usuario.mail_usuario)
                    localStorage.setItem('estado_registro', this.state.usuario.estado_registro)                   
                    window.location.href = "/home"      
                }
                else
                {
                    alert("Usuario o contraseña incorrecta");
                }
                console.log(this.state.usuario);
                   } catch (error) {
                    console.error(error);
                  }
    }
    
    render() {
        return (
            <React.Fragment>           
            
             <HeaderComponent/>
                <body className="page page-login">
                    <main>
                        <img src={imgLoginbg} className="full-bg" alt ="" />
                        <div className="container">
                            <section className="login login--productor">
                                <div className="row">
                                    <div className="col-md-6 pr-md-5">
                                        <header>
                                             <img src={svgiconproduct} alt="" />
                                            <h2>Bienvenido al Portal de Productores del Grupo San Jose Farms</h2>
                                        </header>
                                        <p style={{textAlign: 'justify'}} >
                                           Estimado productor, a continuación usted podrá tener acceso e ingresar al portal de productores. En este portal, usted podrá visualizar información asociadas a recepciones, proceso, calidad, a sus órdenes de compra, estatus de sus facturas, calendario de pagos y el histórico correspondiente de su cuenta con el holding de San Jose Farms. Adicionalmente será el canal de comunicación bidireccional que tendrá con la empresa.
                                        </p>
                                    </div>
                                    <div className="col-md-6 login__col text-center">
                                        <img src={logo} alt ="" />
                                        <nav className="login__nav">
                                            <ul className="list-unstyled d-flex text-center justify-content-center">
                                                <li>
                                                    <a href="/#" onClick={() => {this.mostrarRegistro(true)}} role="button" aria-expanded="false" className={this.state.BtnLoginColorVerde} >
                                                        Iniciar sesión
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/#" onClick={() => {this.mostrarRegistro(false)}} role="button" aria-expanded="true" className={this.state.BtnRegistrarColorVerde} >
                                                        Registrate
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                        {this.state.showButton === false ? 
                                             
                                    <form onSubmit={this.obtenerUsuario} className="login__form active" id="login__form">
                                        <div>
                                            <input type="email" placeholder="Correo" id="mail" required value={this.state.mail} onChange={this.handleChangeMail} />
                                            <img src={iconlogin_user} alt="" />
                                        </div>
                                        <div>
                                            <input type="password" placeholder="Contraseña" required value={this.state.password}  onChange={this.handleChangePass} />
                                            <img src={iconlogin_pass} alt="" />
                                        </div>
                                        <div>
                                            <input type="submit" value="Ingresar" />
                                        </div>
                                        <a href="/#" className="login__recover" >¡Recordar contraseña!</a>
                                    </form>
                                        :    <Registro></Registro> }
                                    </div>
                                </div>
                            </section>
                        </div>
                    </main>
                </body>
                <PiePagina/> 
            </React.Fragment>
        );
    }
}

export default Index;