
import React from 'react';
import PiePagina from './footer'
import Nav from './nav'
import icon_welcome from './img/icon-welcome.svg';
import header_home from './img/calidad.jpg';
import icon_stats from './img/icon-stats.svg';
import icon_stats2 from './img/icon-stats-2.svg';
import icon_pdf from './img/icon-pdf.png';
import { Table, Form } from 'react-bootstrap';
import MaterialTable from "material-table";
import loading from './img/loading.gif';
import axios from 'axios';
import NumberFormat from 'react-number-format';

class Calidad extends React.PureComponent {

    constructor(props) {
        super(props);
        // No llames this.setState() aquí!

        this.state = {
            campos: [],
            materiales: [],
            idtbl_campo: 0,
            lista_especies:[],
            kilos: 0,
            recepciones:0,
            cantidadEspecies: 1,
            esfiltrado : false,
            establacalidad_1: true,
            filtro : '',
            tablaSinFiltrar: []
        };
        this.validaLogin = this.validaLogin.bind(this);
        this.obtenerSelector = this.obtenerSelector.bind(this);
        this.obtenerTabla = this.obtenerTabla.bind(this);
        this.obtenerTablaNuez = this.obtenerTablaNuez.bind(this);
        this.obtenerKilosRecepcionados = this.obtenerKilosRecepcionados.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.obtenerNumerosRecepcionados = this.obtenerNumerosRecepcionados.bind(this);
        this.handleChangeEspecies = this.handleChangeEspecies.bind(this);
        this.obtenerEspecies = this.obtenerEspecies.bind(this);
        
     }

     async obtenerEspecies(value) {
        const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario'), idtbl_campo: value };
        try {
            const res = await axios.post('https://productor.appsjf.cl/sanJoseFarmsPHP/numeroespecies.php', obj);
            this.setState({ lista_especies: res.data });
            if (res.data !== undefined){
                console.log(this.state.lista_especies);
                var cantidad =  res.data.length;
                this.setState({ cantidadEspecies : cantidad})
                console.log(this.state.cantidadEspecies);    
            }
        }catch (error){
            console.error(error);
        }
    }
    componentDidMount() {
        this.validaLogin()
    }

    validaLogin() {
        if (localStorage.getItem('idtbl_usuario') === undefined) {
            alert("Sesion no ha sido iniciada");
            window.location.href = "/"
        } else {
            this.obtenerSelector();
        }
    }
 
    handleChange(event) {
        event.preventDefault();
        if('NCC_GRANEL'!==this.state.idMaterial){
            this.obtenerTabla();
        }else{
            this.obtenerTablaNuez();
        }
 //       this.obtenerKilosRecepcionados(event.target.value);
  //      this.obtenerNumerosRecepcionados(event.target.value);
      //  this.obtenerEspecies(this.state.campos[0].idtbl_campo)
    }
    async obtenerSelector() {
        const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario') };
        try {
            const res = await axios.post('https://productor.appsjf.cl/sanJoseFarmsPHP/selector_especieCalidad.php', obj);
            this.setState({
                materiales: res.data,
            });
            this.setState({ idMaterial: this.state.materiales[0].idMaterial });
            if (this.state.materiales === undefined) {
                alert('Usuario no posee especies asignadas');
                this.setState({ campos: [] })
                var cantidad =  res.data.length;
                this.setState({ cantidadEspecies : cantidad})    
  
            }
        } catch (error) {
            console.error(error);
        }
        this.obtenerSelectorCampos (this.state.materiales[0].idMaterial)
    }

    async obtenerSelectorCampos( value ) {
        const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario'), idMaterial:  value};
        try {
            const res = await axios.post('https://productor.appsjf.cl/sanJoseFarmsPHP/selector_camposCalidad.php', obj);
            this.setState({
                  campos: res.data,
            });
            console.log(this.state.campos)
            if (this.state.campos === undefined) {
                alert('Usuario no posee especies asignadas');
                this.setState({ campos: [] })
            }
        } catch (error) {
            console.error(error);
        }
      this.obtenerTabla()
    }


    handleChangeEspecies(event){
        event.preventDefault();
        
        this.setState({ idMaterial: event.target.value });
        this.obtenerSelectorCampos(event.target.value)
        if('NCC_GRANEL'!==this.state.idMaterial){
            this.obtenerTabla();
        }else{
            this.obtenerTablaNuez();
        }
    }

    async obtenerTablaNuez(value) {
        try {
            this.setState({establacalidad_1:false})
            this.setState({isLoading: true})
            const res = await axios.post('https://productor.appsjf.cl/sanJoseFarmsPHP/tablacalidad2.php');
         
            if (res.data  !== undefined){
                this.setState({isLoading: false})  
                this.setState({tablaCalidad: res.data});
            }
        } catch (error) {
            console.error(error);
        }
    }

    async obtenerTabla(value) {
        try {
            this.setState({establacalidad_1:true})
            this.setState({isLoading: true})
            const res = await axios.post('https://productor.appsjf.cl/sanJoseFarmsPHP/tablacalidad.php');
            console.log(res.data);
            if (res.data  !== undefined){
                this.setState({isLoading: false})  
                this.setState({tablaCalidad: res.data});
            }
        } catch (error) {
            console.error(error);
        }
    }

    async obtenerKilosRecepcionados(value){
        const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario'), idtbl_campo: value };
        try {
            const res = await axios.post('https://productor.appsjf.cl/sanJoseFarmsPHP/kilosrecepcionados.php', obj);
            this.setState({
                kilos: res.data[0]
            });

        } catch (error) {
            console.error(error);
        }
    }
    async obtenerNumerosRecepcionados(value){
        const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario'), idtbl_campo: value };
        try {
            const res = await axios.post('https://productor.appsjf.cl/sanJoseFarmsPHP/numerorecepciones.php', obj);
            this.setState({
                recepciones: res.data[0]
            });
        } catch (error) {
            console.error(error);
        }
    }
    render() {
        return (
            <React.Fragment>
                <body class="page page-home">
                    <Nav></Nav>
                    <main>
                        <div class="page-header-img">
                            <img src={header_home} alt="" />
                        </div>
                        <section class="page__intro">
                            <div class="container">
                                <header>
                                    <img src={icon_welcome} alt="" />
                                    <h4>Calidad</h4>
                                </header>
                                <p>Estimado productor en este sitio encontrará información relevante de la calidad de su producción.</p>
                            </div>
                        </section>

                        <section className="table table-responsive mt-md-5">
                        <div className="container table">
                            <div className="table__header table__row border-radius--normal bg--white">
                                <div className="row">
                                    <div className="col-4 col-md">
                                        <label className="form-label"><h2>Especies</h2></label>
                                        {
                                            <Form.Control as="select" onChange={this.handleChangeEspecies}>
                                                    {this.state.materiales.map((r) => { 
                                                        return (< option key={r.idMaterial} value={r.idMaterial}> {r.material} </option>) 
                                                    }  )}
                                            </Form.Control>
                                        }
                                    </div>  
                                    <div className="col-4 col-md">
                                        <label className="form-label"><h2>Campos</h2></label>
                                        {
                                            <Form.Control as="select" defaultValue={this.state.campos[0]} id={"1"} onChange={this.handleChange}>
                                                {this.state.campos.map((r) => { return (< option key={r.idCampo} value={r.idCampo}> {r.campo} </option>) })}
                                            </Form.Control>
                                         }   
                                    </div>
                                  <div className="col-4 col-md"></div>                                   
                                </div> 
                            </div> 
                        </div>
                    </section>


                        <section class="number-blocks">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-6">
                                        <article class="number-block block border-radius--big bg--green"> <a href="">+</a>
                                            <header>
                                                <img src={icon_stats} alt="" />
                                                <h2>Total de muestreos</h2>
                                            </header>
                                            <div class="number-block__content"> <span class="number">{<NumberFormat value={this.state.cantidadEspecies} displayType={'text'} thousandSeparator={true} /> }</span>
                                              
                                            </div>
                                        </article>
                                    </div>
                                    <div class="col-md-6">
                                        <article class="number-block block border-radius--big bg--light-green"> <a href="#">+</a>
                                            <header>
                                                <img alt="" src={icon_stats2} />
                                                <h2>N° Especies analizadas</h2>
                                            </header>
                                            <div class="number-block__content"> <span class="number">{<NumberFormat value={this.state.cantidadEspecies} displayType={'text'} thousandSeparator={true} /> }</span>
                                        
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>

                    { this.state.isLoading === true ? 
                    
                   <section className="table table-responsive mt-md-5">
                        <div className="container table">
                          <div align="center"><img src={loading} width="15%" alt="loading..." /></div>
                          </div>
                    </section>
                        : this.state.establacalidad_1 === true ?
                    <section className="table table-responsive mt-md-5">
                        <div className="container table">
                            <MaterialTable columns={[
                                { title: "especie_calidad",  field: "especie_calidad", align:"center" ,  filtering: false},
                                { title: "vendor_calidad", field: "vendor_calidad",     filtering: false },
                                { title: "lote_calidad",  field: "lote_calidad",  filtering: true, searchable: true },
                                { title: "registro_calidad", field: "registro_calidad", align:"center" , filtering: false },
                                { title: "nombre_variedad",  field: "nombre_variedad", align:"left" ,  filtering: false },
                                { title: "fecha_calidad",     field: "fecha_calidad",   align:"left",  filtering: false },
                                { title: "url_calidad",        field: "url_calidad", align:"right" , filtering: false ,  render: rowData => <a target={"_blank"} href={rowData.url_calidad}> <img src={icon_pdf} height={45}  /> </a> }]}
                                data= {this.state.tablaCalidad }
                                options={{ filtering: false, search: false, showTitle: false,  exportAllData: true, exportButton: true, exportFileName: 'Reporte Recepción San Jose Farms' }}
                            />
                        </div>
                    </section>
                      :
                      <section className="table table-responsive mt-md-5">
                        <div className="container table">
                            <MaterialTable columns={[
                               {title:"GUIA",field:"GUIA",align:"left",filtering:false},
                               {title:"usuario",field:"usuario",align:"left",filtering:false},
                               {title:"centro",field:"centro",align:"left",filtering:false},
                               {title:"FINALIZADA",field:"FINALIZADA",align:"left",filtering:false},
                               {title:"MOV323",field:"MOV323",align:"left",filtering:false},
                               {title:"lotesGuia",field:"lotesGuia",align:"left",filtering:false},
                               {title:"PRODUCTOR",field:"PRODUCTOR",align:"left",filtering:false},
                               {title:"VARIEDAD",field:"VARIEDAD",align:"left",filtering:false},
                               {title:"EST_FUMIGADO",field:"EST_FUMIGADO",align:"left",filtering:false},
                               {title:"IDGUIA",field:"IDGUIA",align:"left",filtering:false},
                               {title:"MUESTRA",field:"MUESTRA",align:"left",filtering:false},
                               {title:"infoText",field:"infoText",align:"left",filtering:false},
                               {title:"infoStyle",field:"infoStyle",align:"left",filtering:false},
                               {title:"infoTextExt",field:"infoTextExt",align:"left",filtering:false},
                               {title:"motivoRechazoExt",field:"motivoRechazoExt",align:"left",filtering:false},
                               {title:"dureza",field:"dureza",align:"left",filtering:false},
                               {title:"totalDefectosExt",field:"totalDefectosExt",align:"left",filtering:false},
                               {title:"totalDefectosExtP",field:"totalDefectosExtP",align:"left",filtering:false},
                               {title:"estimadoExportable",field:"estimadoExportable",align:"left",filtering:false},
                               {title:"estimadoExportableP",field:"estimadoExportableP",align:"left",filtering:false},
                               {title:"infoTextInt",field:"infoTextInt",align:"left",filtering:false},
                               {title:"motivoRechazoInt",field:"motivoRechazoInt",align:"left",filtering:false},
                               {title:"MUESTRAKG",field:"MUESTRAKG",align:"left",filtering:false},
                               {title:"totalPulpaKg",field:"totalPulpaKg",align:"left",filtering:false},
                               {title:"totalPulpaKgP",field:"totalPulpaKgP",align:"left",filtering:false},
                               {title:"totalCascaraKg",field:"totalCascaraKg",align:"left",filtering:false},
                               {title:"totalCascaraKgP",field:"totalCascaraKgP",align:"left",filtering:false},
                               {title:"totalDefectosInt",field:"totalDefectosInt",align:"left",filtering:false},
                               {title:"totalDefectosIntP",field:"totalDefectosIntP",align:"left",filtering:false},
                               {title:"totalMariposas",field:"totalMariposas",align:"left",filtering:false},
                               {title:"totalCuarto",field:"totalCuarto",align:"left",filtering:false},
                               {title:"totalCuartillo",field:"totalCuartillo",align:"left",filtering:false},
                               {title:"totalMariposasP",field:"totalMariposasP",align:"left",filtering:false},
                               {title:"totalCuartoP",field:"totalCuartoP",align:"left",filtering:false},
                               {title:"totalCuartilloP",field:"totalCuartilloP",align:"left",filtering:false},
                               {title:"colorExtraLight",field:"colorExtraLight",align:"left",filtering:false},
                               {title:"colorLight",field:"colorLight",align:"left",filtering:false},
                               {title:"colorLightAmbar",field:"colorLightAmbar",align:"left",filtering:false},
                               {title:"colorAmbar",field:"colorAmbar",align:"left",filtering:false},
                               {title:"colorAmarilla",field:"colorAmarilla",align:"left",filtering:false},
                               {title:"colorNaranja",field:"colorNaranja",align:"left",filtering:false},
                               {title:"colorExtraLightP",field:"colorExtraLightP",align:"left",filtering:false},
                               {title:"colorLightP",field:"colorLightP",align:"left",filtering:false},
                               {title:"colorLightAmbarP",field:"colorLightAmbarP",align:"left",filtering:false},
                               {title:"colorAmbarP",field:"colorAmbarP",align:"left",filtering:false},
                               {title:"colorAmarillaP",field:"colorAmarillaP",align:"left",filtering:false},
                               {title:"colorNaranjaP",field:"colorNaranjaP",align:"left",filtering:false},
                               {title:"motivoRechazo",field:"motivoRechazo",align:"left",filtering:false},
                               {title:"title",field:"title",align:"left",filtering:false},
                               {title:"HUMEDAD.HUMEDADPONDE",field:"HUMEDAD.HUMEDADPONDE",align:"left",filtering:false},
                               {title:"HUMEDAD.HUMEDADPULPA",field:"HUMEDAD.HUMEDADPULPA",align:"left",filtering:false},
                               {title:"HUMEDAD.DUREZA",field:"HUMEDAD.DUREZA",align:"left",filtering:false},
                               {title:"CALIBRE.MUESTRA",field:"CALIBRE.MUESTRA",align:"left",filtering:false},
                               {title:"CALIBRE.C26",field:"CALIBRE.C26",align:"left",filtering:false},
                               {title:"CALIBRE.C26_28",field:"CALIBRE.C26_28",align:"left",filtering:false},
                               {title:"CALIBRE.C28_30",field:"CALIBRE.C28_30",align:"left",filtering:false},
                               {title:"CALIBRE.C30_32",field:"CALIBRE.C30_32",align:"left",filtering:false},
                               {title:"CALIBRE.C32_34",field:"CALIBRE.C32_34",align:"left",filtering:false},
                               {title:"CALIBRE.C34_36",field:"CALIBRE.C34_36",align:"left",filtering:false},
                               {title:"CALIBRE.C36",field:"CALIBRE.C36",align:"left",filtering:false},
                               {title:"CALIBRE.TOTAL",field:"CALIBRE.TOTAL",align:"left",filtering:false},
                               {title:"EXTERNO.CASCOABIERTO",field:"EXTERNO.CASCOABIERTO",align:"left",filtering:false},
                               {title:"EXTERNO.QUEBRADATRIZADA",field:"EXTERNO.QUEBRADATRIZADA",align:"left",filtering:false},
                               {title:"EXTERNO.MANCHAGRAVE",field:"EXTERNO.MANCHAGRAVE",align:"left",filtering:false},
                               {title:"EXTERNO.MANCHALEVE",field:"EXTERNO.MANCHALEVE",align:"left",filtering:false},
                               {title:"EXTERNO.PELONADHERIDO",field:"EXTERNO.PELONADHERIDO",align:"left",filtering:false},
                               {title:"EXTERNO.TIERRA",field:"EXTERNO.TIERRA",align:"left",filtering:false},
                               {title:"EXTERNO.OTRAVARIEDAD",field:"EXTERNO.OTRAVARIEDAD",align:"left",filtering:false},
                               {title:"INTERNO.MUESTRA",field:"INTERNO.MUESTRA",align:"left",filtering:false},
                               {title:"INTERNO.RESECAGRAVEVANA",field:"INTERNO.RESECAGRAVEVANA",align:"left",filtering:false},
                               {title:"INTERNO.RESECALEVE",field:"INTERNO.RESECALEVE",align:"left",filtering:false},
                               {title:"INTERNO.DANOINSECTO",field:"INTERNO.DANOINSECTO",align:"left",filtering:false},
                               {title:"INTERNO.HONGOINACTIVO",field:"INTERNO.HONGOINACTIVO",align:"left",filtering:false},
                               {title:"INTERNO.HONGOACTIVO",field:"INTERNO.HONGOACTIVO",align:"left",filtering:false},
                               {title:"INTERNO.RANCIDEZ",field:"INTERNO.RANCIDEZ",align:"left",filtering:false},
                               {title:"INTERNO.IMPUREZA_MATERIAEXTRANA",field:"INTERNO.IMPUREZA_MATERIAEXTRANA",align:"left",filtering:false},
                               {title:"INTERNO.MANCHAGRAVE",field:"INTERNO.MANCHAGRAVE",align:"left",filtering:false},
                               {title:"INTERNO.MANCHALEVE",field:"INTERNO.MANCHALEVE",align:"left",filtering:false},
                               {title:"INTERNO.MANCHANARANJA",field:"INTERNO.MANCHANARANJA",align:"left",filtering:false},
                               {title:"INTERNO.TIERRA",field:"INTERNO.TIERRA",align:"left",filtering:false},
                               {title:"FRACCIONAMIENTO_COLOR[0].EXTRALIGHT",field:"FRACCIONAMIENTO_COLOR[0].EXTRALIGHT",align:"left",filtering:false},
                               {title:"FRACCIONAMIENTO_COLOR[0].EXTRALIGHTBC",field:"FRACCIONAMIENTO_COLOR[0].EXTRALIGHTBC",align:"left",filtering:false},
                               {title:"FRACCIONAMIENTO_COLOR[0].LIGHT",field:"FRACCIONAMIENTO_COLOR[0].LIGHT",align:"left",filtering:false},
                               {title:"FRACCIONAMIENTO_COLOR[0].LIGHTAMBAR",field:"FRACCIONAMIENTO_COLOR[0].LIGHTAMBAR",align:"left",filtering:false},
                               {title:"FRACCIONAMIENTO_COLOR[0].AMBAR",field:"FRACCIONAMIENTO_COLOR[0].AMBAR",align:"left",filtering:false},
                               {title:"FRACCIONAMIENTO_COLOR[1].EXTRALIGHT",field:"FRACCIONAMIENTO_COLOR[1].EXTRALIGHT",align:"left",filtering:false},
                               {title:"FRACCIONAMIENTO_COLOR[1].AMBAR",field:"FRACCIONAMIENTO_COLOR[1].AMBAR",align:"left",filtering:false},
                               {title:"FRACCIONAMIENTO_COLOR[2].EXTRALIGHT",field:"FRACCIONAMIENTO_COLOR[2].EXTRALIGHT",align:"left",filtering:false},
                               {title:"FRACCIONAMIENTO_COLOR[2].AMBAR",field:"FRACCIONAMIENTO_COLOR[2].AMBAR",align:"left",filtering:false},
                               {title:"FRACCIONAMIENTO_COLOR[3].AMARILLA",field:"FRACCIONAMIENTO_COLOR[3].AMARILLA",align:"left",filtering:false},
                               {title:"FRACCIONAMIENTO_COLOR[3].NARANJA",field:"FRACCIONAMIENTO_COLOR[3].NARANJA",align:"left",filtering:false},
                               {title:"CONTEO_MARIPOSA.UNIDADES",field:"CONTEO_MARIPOSA.UNIDADES",align:"left",filtering:false},
                               {title:"CONTEO_MARIPOSA.CALIBRE_ESTIMADO",field:"CONTEO_MARIPOSA.CALIBRE_ESTIMADO",align:"left",filtering:false},
                               {title:"EVALUACION",field:"EVALUACION",align:"left",filtering:false}]}
                                data= {this.state.tablaCalidad }
                                options={{ filtering: false, search: false, showTitle: false,  exportAllData: true, exportButton: true, exportFileName: 'Reporte Recepción San Jose Farms' }}
                            />
                        </div>
                    </section>
                      
                    }
                </body>
                <PiePagina></PiePagina>
            </React.Fragment >
        )
    }
}


export default Calidad;