
import React from 'react';
import PiePagina from './footer'
import Nav from './nav'
import icon_welcome from './img/icon-welcome.svg';
import header_home from './img/header-home.jpg';
import axios from 'axios';
import MaterialTable from "material-table";
import loading from './img/loading.gif';

class Agronomo extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            tablaagronomo:[],
            isLoading : true
           };
    }
    componentDidMount() {
        this.validaLogin()
    }

    validaLogin() {
        if (localStorage.getItem('idtbl_usuario') === undefined) {
            alert("Sesion no ha sido iniciada");
            window.location.href = "/"
        } else {
            this.obtenerTabla();
        }
    }

 obtenerTabla() {
        const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario') };
        let data = []
        try { 
            this.setState({isLoading: true})
           axios.post('https://productor.appsjf.cl/sanJoseFarmsPHP/tablaagronomo.php', obj).then(response => 
             {
                console.log(obj);
                console.log(response.data);
                if (response.data !== undefined || response.data !== []){
                    this.setState({tablaagronomo: response.data})
                    this.setState({isLoading: false})
                }else{
                    console.log("data vacia");
                }
            })                
         }catch(error) {
            console.error(error);
         }
    }
    render() {
        return (
            <React.Fragment>
                <Nav></Nav>
                <body className="page page-home">
                    <main>
                        <div className="page-header-img">
                            <img src={header_home} alt="" />
                        </div>
                        <section className="page__intro">
                            <div className="container">
                                <header>
                                    <img src={icon_welcome} alt="" />
                                    <h4>Agrónomo</h4>
                                </header>
                                <p>Estimado productor en este se podrán ingresar sus datos. </p>
                            </div>
                        </section>
                        { this.state.isLoading === true ? 
                    
                    <section className="table table-responsive mt-md-5">
                        <div className="container table">
                          <div align="center"><img src={loading} width="15%" alt="loading..." /></div>
                          </div>
                    </section>
                        : 
                    <section className="table table-responsive mt-md-5">
                        <div className="container table">
                            <MaterialTable columns={[
                                { title: "ID",        field: "idCampo", align:"center" ,  filtering: false},
                                { title: "nombre_campo", field: "nombre_campo",     filtering: false },
                                { title: "direccion_campo",  field: "direccion_campo",  filtering: true, searchable: true },
                                { title: "nombre_vendor",      field: "nombre_vendor",  align:"center" ,    type: "numeric", filtering: false}]}
                                data= {this.state.tablaagronomo }
                                editable={{
                                    onRowAdd: newData =>
                                      new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                      //    setData([...data, newData]);
                                          
                                          resolve();
                                        }, 1000)
                                      }),
                                    onRowUpdate: (newData, oldData) =>
                                      new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                          //const dataUpdate = [...data];
                                         // const index = oldData.tableData.id;
                                         // dataUpdate[index] = newData;
                                          //setData([...dataUpdate]);
                            
                                          resolve();
                                        }, 1000)
                                      }),
                                    onRowDelete: oldData =>
                                      new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                         // const dataDelete = [...data];
                                         // const index = oldData.tableData.id;
                                         // dataDelete.splice(index, 1);
                                          // setData([...dataDelete]);
                                          
                                          resolve()
                                        }, 1000)
                                      }),
                                  }}
                                options={{ filtering: false, search: false, showTitle: false,  exportAllData: true, exportButton: true, exportFileName: 'Reporte San Jose Farms' }}
                            />
                        </div>
                    </section>
                    }
                    </main>
                </body>
                <PiePagina></PiePagina>
            </React.Fragment >
        )
    }
}

export default Agronomo;